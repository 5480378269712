import { createSlice } from "@reduxjs/toolkit";

const webinarsSlice = createSlice({
  name: "webinars",
  initialState: {
    arrayOfWebinarData: [],
    webinarsData: {},
    currentCategory: "All",
  },
  reducers: {
    setArrayOfWebinarData: (state, action) => {
      state.arrayOfWebinarData = action.payload;
    },
    setWebinarsData: (state, action) => {
      state.webinarsData = action.payload;
    },
    handleCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
  },
});

export const { setArrayOfWebinarData, setWebinarsData, handleCategory } = webinarsSlice.actions;

export default webinarsSlice.reducer;