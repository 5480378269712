import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import ErrorButton from "./ErrorButton";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    if (window.location.hostname !== 'localhost') {
      const errorMessage = error.message ?? "";
      if (errorMessage.includes("[object Object]")) return;
      if (errorMessage.includes("Request")) return;
      if (errorMessage.includes("Network Error")) return;

      this.sendErrorReport(error, errorInfo);
    }
    console.log("ERROR BOUNDARY", `${error}: Information: ${errorInfo}`)
  }

  async sendErrorReport(error, errorInfo) {
    const apiUrl = 'https://us-central1-webinarstvus.cloudfunctions.net/sendErrorReport';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      },
    };

    const payload = {
      error: error.toString(),
      errorInfo: errorInfo.componentStack,
      id: this.props.id,
      userData: JSON.stringify(this.props.userData),
      path: window.location.href,
      deviceInfo: navigator.userAgent,
      time: new Date().toISOString(),
    };
    try {
      await axios.post(apiUrl, payload, config);
      console.log('Error report sent successfully');
    } catch (err) {
      console.log('Failed to send error report', err);
    }
  }

  goBack = () => {
    if (window.history.length > 1) {
      window.history.go(-2);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      window.location.href = '/';
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  render() {
    if (this.state.hasError) {
      return <ErrorButton goBack={this.goBack} />;
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.firebaseToken,
    id: state.event.webinarEvent.id,
    userData: state.user.userData
  };
};

export default connect(mapStateToProps)(ErrorBoundary);