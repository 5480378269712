import { createSlice } from "@reduxjs/toolkit";

const webinarSlice = createSlice({
  name: "webinar",
  initialState: {
    webinarData: {},
    mainWebinarData: {},
    noHost: false,
    fromSearch: false,
  },
  reducers: {
    setWebinarData: (state, action) => {
      state.webinarData = action.payload;
    },
    setMainWebinarData: (state, action) => {
      state.mainWebinarData = action.payload;
    },
  },
});

export const { setWebinarData, setMainWebinarData } = webinarSlice.actions;

export default webinarSlice.reducer;