import { createSlice } from "@reduxjs/toolkit";

const webinarEventSlice = createSlice({
  name: "event",
  initialState: {
    webinarEvent: {
      webinarId: "",
      topic: "",
      description: "",
      date: "",
      category: "",
      subcategory: "",
      other: "",
      meetingLength: 60,
      meetingEndTime: 100,
      addToSearch: false,
      date: "",
      meetingStartTime: 0,
      timezoneOffset: "",
      timezoneAbbreviation: "",
      icalObject: "",
      banner: "",
      featureImage: "",
      optionalMessage: "",
      thankYouMessage: "Thank you for registering!"
    },
    allEvents: [],
    filteredWebinars: [],
    searchQuery: "",
    searchParticipatedQuery: "",
    archivedWebinars: [],
    webinarStatistics: {},
    peopleData: [],
    filteredPeople: [],
    peopleSearchQuery: "",
    registeredData: 0,
    attendedData: 0,
    joinedFilterType: "all",
    registerWebinarEvent: {},
    participatedWebinars: [],
    participatedFilteredWebinars: [],
    messageHistory: [],
    filteredMessages: [],
    messageSearchQuery: "",
    questionHistory: [],
    directMessageHistory: [],
    questionsOrMessagesHistory: [],
    totalCTAClicks: 0,
    uniqueCTAClicks: 0,
    usersClickedCTA: [],
    ctaDataForChart: [],
    webinarStartEndTime: {},
    speakers: [],
    pollQuestionIndex: 0,
    multipleChoicePollResults: [],
    shortAnswerPollResults: [],
    multipleChoiceOrShortAnswer: [],
    fromPpl: false,
    utmSource: "none",
  },
  reducers: {
    setWebinarEvent: (state, action) => {
      state.webinarEvent = action.payload;
    },
    setRegisterWebinarEvent: (state, action) => {
      state.registerWebinarEvent = action.payload;
    },
    setFromPpl: (state, action) => {
      state.fromPpl = action.payload;
    },
    updateWebinarEventField: (state, action) => {
      const { field, value } = action.payload;
      state.webinarEvent[field] = value;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    setArchivedWebinars: (state, action) => {
      state.archivedWebinars = action.payload;
    },
    setParticipatedWebinars: (state, action) => {
      state.participatedWebinars = action.payload;
    },
    setParticipatedFilteredWebinars: (state, action) => {
      state.participatedFilteredWebinars = action.payload;
    },
    setFilteredWebinars: (state, action) => {
      state.filteredWebinars = action.payload;
    },
    setFilteredPeople: (state, action) => {
      state.filteredPeople = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSearchParticipatedQuery: (state, action) => {
      state.searchParticipatedQuery = action.payload;
    },
    setPeopleSearchQuery: (state, action) => {
      state.peopleSearchQuery = action.payload;
    },
    deleteWebinar: (state, action) => {
      const webinarIdToDelete = action.payload;
      state.allEvents = state.allEvents.filter(webinar => webinar.webinarId !== webinarIdToDelete);
      state.participatedWebinars = state.participatedWebinars.filter(webinar => webinar.webinarId !== webinarIdToDelete);
      state.archivedWebinars = state.archivedWebinars.filter(webinar => webinar.webinarId !== webinarIdToDelete);
    },
    addWebinarToArchive: (state, action) => {
      const { id, webinar } = action.payload;
      const archivedWebinarIndex = state.allEvents.findIndex((webinar) => webinar.id === id);

      if (archivedWebinarIndex !== -1) {
        state.allEvents = state.allEvents.filter(webinar => webinar.id !== id);
        const archived = state.archivedWebinars;
        archived.push(webinar);
        state.archivedWebinars = archived;
      }
    },
    setUnarchivedWebinar: (state, action) => {
      const { webinar, type } = action.payload;

      const removeIfArchived = (webinarId) => {
        state.archivedWebinars = state.archivedWebinars.filter(w => w.webinarId !== webinarId);
      };

      if (type === "host") {
        state.allEvents.push(webinar);
        removeIfArchived(webinar.webinarId);
      } else if (type === "attendee") {
        state.participatedWebinars.push(webinar);
        removeIfArchived(webinar.webinarId);
      }
    },
    setWebinarStatistics: (state, action) => {
      state.webinarStatistics = action.payload;
    },
    setPeopleData: (state, action) => {
      state.peopleData = action.payload;
    },
    setRegisteredTotal: (state, action) => {
      state.registeredData = action.payload;
    },
    setAttendedTotal: (state, action) => {
      state.attendedData = action.payload;
    },
    setJoinedFilterType: (state, action) => {
      state.joinedFilterType = action.payload;
    },
    updateWebinarInList: (state, action) => {
      const updatedWebinar = action.payload;
      const index = state.allEvents.findIndex(webinar => webinar.id === updatedWebinar.id);
      if (index !== -1) {
        state.allEvents[index] = updatedWebinar;
      }
    },
    setMessageHistory: (state, action) => {
      state.messageHistory = action.payload;
    },
    setFilteredMessages: (state, action) => {
      state.filteredMessages = action.payload;
    },
    setMessageSearchQuery: (state, action) => {
      state.messageSearchQuery = action.payload;
    },
    setQuestionHistory: (state, action) => {
      state.questionHistory = action.payload;
    },
    setDirectMessageHistory: (state, action) => {
      state.directMessageHistory = action.payload;
    },
    setQuestionsOrMessages: (state, action) => {
      state.questionsOrMessagesHistory = action.payload;
    },
    setTotalCTAClicks: (state, action) => {
      state.totalCTAClicks = action.payload;
    },
    setUniqueCTAClicks: (state, action) => {
      state.uniqueCTAClicks = action.payload;
    },
    setUsersClickedCTA: (state, action) => {
      state.usersClickedCTA = action.payload;
    },
    setCtaDataForChart: (state, action) => {
      state.ctaDataForChart = action.payload;
    },
    setWebinarStartEndTime: (state, action) => {
      state.webinarStartEndTime = action.payload;
    },
    setSpeakers: (state, action) => {
      state.speakers = action.payload;
    },
    updateSpeakers: (state, action) => {
      const { id } = action.payload;
      const updatedSpeakers = state.speakers.filter( item => item.id !== id)
      state.speakers = updatedSpeakers;
    },
    setPollQuestionIndex: (state, action) => {
      state.pollQuestionIndex = action.payload;
    },
    setMultipleChoiceResults: (state, action) => {
      state.multipleChoicePollResults = action.payload;
    },
    setShortAnswerResults: (state, action) => {
      state.shortAnswerPollResults = action.payload;
    },
    setShortOrMultiple: (state, action) => {
      state.multipleChoiceOrShortAnswer = action.payload;
    },
    setUtmSource: (state, action) => {
      state.utmSource = action.payload;
    },
  }
});

export const {
  setWebinarEvent,
  updateWebinarEventField,
  setAllEvents,
  setArchivedWebinars,
  setSearchQuery,
  setFilteredWebinars,
  deleteWebinar,
  addWebinarToArchive,
  setWebinarStatistics,
  setPeopleData,
  setFilteredPeople,
  setPeopleSearchQuery,
  setRegisteredTotal,
  setAttendedTotal,
  setJoinedFilterType,
  updateWebinarInList,
  setRegisterWebinarEvent,
  setParticipatedWebinars,
  setParticipatedFilteredWebinars,
  setSearchParticipatedQuery,
  setMessageHistory,
  setFilteredMessages,
  setMessageSearchQuery,
  setQuestionHistory,
  setDirectMessageHistory,
  setQuestionsOrMessages,
  setTotalCTAClicks,
  setUniqueCTAClicks,
  setUnarchivedWebinar,
  setUsersClickedCTA,
  setCtaDataForChart,
  setWebinarStartEndTime,
  setSpeakers,
  updateSpeakers,
  setPollQuestionIndex,
  setMultipleChoiceResults,
  setShortAnswerResults,
  setShortOrMultiple,
  setFromPpl,
  setUtmSource,
} = webinarEventSlice.actions;
export default webinarEventSlice.reducer;